import React from 'react';

interface Item {
  codigo_suscripcion: string;
  payment_option: string;
  recurrency: string;
  last_payment: string | null;
  next_payment: string | null;
  payment_method: string;
  subscr_status: string;
  cif_account: string;
  legal_name_account: string;
  cif_consultant: string;
  legal_name_consultant: string;
  descripcion: string;
  price: number;
  remuneration: number | null;
}

interface ListProps {
  items: Item[];
}

const List: React.FC<ListProps> = ({ items }) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-bordered mt-4">
        <thead className="table-dark">
          <tr>
            <th scope="col">Código Suscripción</th>

            <th scope="col">Estado</th>
            <th scope="col">CIF Cuenta</th>
            <th scope="col">Nombre Legal Cuenta</th>
            <th scope="col">CIF Consultor</th>
            <th scope="col">Nombre Legal Consultor</th>
            <th scope="col">Descripción</th>
            <th scope="col">Precio</th>
            <th scope="col">Recurrencia</th>
            <th scope="col">Último Pago</th>
            <th scope="col">Próximo Pago</th>
            <th scope="col">Método de Pago</th>
            <th scope="col">Remuneración</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 ? (
            items.map((item) => (
              <tr key={item.codigo_suscripcion}>
                <td>{item.codigo_suscripcion}</td>
                <td>{item.subscr_status}</td>
                <td>{item.cif_account}</td>
                <td>{item.legal_name_account}</td>
                <td>{item.cif_consultant}</td>
                <td>{item.legal_name_consultant}</td>
                <td>{item.descripcion}</td>
                <td>{(item.price ?? 0).toFixed(2)} €</td>
                <td>{item.recurrency}</td>
                <td>
                  {item.last_payment
                    ? new Date(item.last_payment).toLocaleString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                      })
                    : ''}
                </td>
                <td>
                  {item.next_payment
                    ? new Date(item.next_payment).toLocaleString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                      })
                    : ''}
                </td>
                <td>{item.payment_method}</td>
                <td>
                  {item.remuneration !== null
                    ? item.remuneration.toFixed(2)
                    : ''}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={15} className="text-center">
                No se encontraron datos.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  
};

export default List;
