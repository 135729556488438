import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code) {

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

      fetch(`${apiBaseUrl}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grant_type: 'authorization_code',
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_REDIRECT_URI,
          code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            localStorage.setItem('authToken', data.access_token);

            navigate('/dashboard');
          } else {
            console.error('Error obteniendo el token:', data);
          }
        })
        .catch((error) => {
          console.error('Error durante el intercambio del código:', error);
        });
    } else {
      console.error('Código no encontrado en el callback.');
      navigate('/login');
    }
  }, [navigate]);

  return <div>Procesando autenticación...</div>;
};

export default Callback;
