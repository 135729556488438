import React from 'react';

type Filters = {
  codigo_suscripcion: string;
  payment_method: string;
  payment_option: string;
  subscr_status: string;
  cif_account: string;
  legal_name_account: string;
  descripcion: string;
  recurrency: string; 
  last_payment: string | null;
  next_payment: string | null;
  remuneration: string | null;
};

interface FilterProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const Filter: React.FC<FilterProps> = ({ filters, setFilters }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  return (
    <div className="filter-container">
      <input
        type="text"
        name="codigo_suscripcion"
        value={filters.codigo_suscripcion}
        onChange={handleInputChange}
        placeholder="Código de suscripción"
        className="form-control mb-2"
      />
      <input
        type="text"
        name="payment_method"
        value={filters.payment_method}
        onChange={handleInputChange}
        placeholder="Método de pago"
        className="form-control mb-2"
      />
      <input
        type="text"
        name="subscr_status"
        value={filters.subscr_status}
        onChange={handleInputChange}
        placeholder="Estado de suscripción"
        className="form-control mb-2"
      />
      <input
        type="text"
        name="cif_account"
        value={filters.cif_account}
        onChange={handleInputChange}
        placeholder="CIF Cuenta"
        className="form-control mb-2"
      />


    </div>
  );
};

export default Filter;
