import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginScreen.css';

const LoginScreen: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/dashboard');
    }
    const code = queryParams.get('code');
    if (code) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grant_type: 'authorization_code',
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_REDIRECT_URI,
          code: code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            localStorage.setItem('authToken', data.access_token);
            navigate('/dashboard'); 
          } else {
            console.error('Error obteniendo el token:', data);
          }
        })
        .catch((error) => {
          console.error('Error durante la solicitud de token:', error);
        });
    }
  }, [navigate]);

  const handleLogin = () => {
    const redirect = process.env.REACT_APP_REDIRECT_URI ? process.env.REACT_APP_REDIRECT_URI:"";
    const authUrl = `${process.env.REACT_APP_API_BASE_URL}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
        redirect
    )}`;
    window.location.href = authUrl;
  };

  return (
    <div className="login-page">
      {/* Logo en la parte superior */}
      <div className="login-header">
        <img
          src="/Logos_rgb-07.png"
          className="login-logo"
        />
      </div>

      {/* Cuerpo central de la página */}
      <Container className="login-container d-flex flex-column justify-content-center align-items-center text-center">
        <h1 className="login-title">Bienvenido al Panel de Suscripciones</h1>
        <Button onClick={handleLogin} className="custom-login-button">
          Conectarse con ConnectBox
        </Button>
      </Container>

      {/* Footer */}
      <div className="login-footer">
        <p>© {new Date().getFullYear()} DEH Online. Todos los derechos reservados.</p>
      </div>
    </div>
  );
};

export default LoginScreen;
