import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Filter from './Filter';
import ListWithPagination from './ListWithPagination';
import { Navbar, Container, Nav, Button, Modal } from 'react-bootstrap';
import { Filter as FilterIcon, List as ListIcon, Gear } from 'react-bootstrap-icons';
import '../styles/Dashboard.css';

type Filters = {
  codigo_suscripcion: string;
  payment_method: string;
  payment_option: string;
  subscr_status: string;
  cif_account: string;
  legal_name_account: string;
  descripcion: string;
  recurrency: string;
  last_payment: string | null;
  next_payment: string | null;
  remuneration: string | null;
};

const Dashboard: React.FC = () => {
  const [filters, setFilters] = useState<Filters>({
    codigo_suscripcion: '',
    payment_method: '',
    payment_option: '',
    subscr_status: '',
    cif_account: '',
    legal_name_account: '',
    descripcion: '',
    recurrency: '',
    last_payment: null,
    next_payment: null,
    remuneration: null,
  });

  const [tempFilters, setTempFilters] = useState<Filters>({
    codigo_suscripcion: '',
    payment_method: '',
    payment_option: '',
    subscr_status: '',
    cif_account: '',
    legal_name_account: '',
    descripcion: '',
    recurrency: '',
    last_payment: null,
    next_payment: null,
    remuneration: null,
  });

  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const handleClearFilters = () => {
    setTempFilters({
      codigo_suscripcion: '',
      payment_method: '',
      payment_option: '',
      subscr_status: '',
      cif_account: '',
      legal_name_account: '',
      descripcion: '',
      recurrency: '',
      last_payment: null,
      next_payment: null,
      remuneration: null,
    });
  };

  const handleApplyFilters = () => {
    setFilters(tempFilters);
    setShowFilters(false);
  };

  const handleLogout = async () => {
    const token = localStorage.getItem('authToken');
    const oauthLogoutUrl = process.env.REACT_APP_API_BASE_URL + '/auth/logout';
    const postLogoutRedirectUri = process.env.REACT_APP_REDIRECT_URI ? process.env.REACT_APP_REDIRECT_URI : "";

    if (token) {
      try {
        await fetch(`${apiBaseUrl}/auth/revoke`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        localStorage.removeItem('authToken');

        const logoutUrl = `${oauthLogoutUrl}?redirectUri=${encodeURIComponent(postLogoutRedirectUri)}`;
        window.location.href = logoutUrl;
      } catch (error) {
        console.error('Error cerrando la sesión:', error);
        localStorage.removeItem('authToken');
        window.location.href = postLogoutRedirectUri;
      }
    } else {
      console.warn('No hay token para cerrar sesión');
      window.location.href = postLogoutRedirectUri;
    }
  };

  return (
    <div className="dashboard-page">
      {/* Navbar */}
      <Navbar bg="danger" variant="dark" expand="lg" sticky="top" className="shadow">
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src="/Logos_rgb-07.png"
              alt="DEH Online"
              className="d-inline-block align-top"
              height="40"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#subscriptions">
                <ListIcon /> Suscripciones
              </Nav.Link>
              <Nav.Link href="#settings">
                <Gear /> Configuración
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link onClick={handleLogout}>Cerrar Sesión</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      <div className="custom-container mt-4">
        <div className="text-center mb-4">
          <h2 className="text-red-shadow">Listado de Suscripciones</h2>
        </div>

        {/* Filter Button */}
        <div className="d-flex justify-content-end mb-3">
          <Button
            variant="outline-danger"
            onClick={() => setShowFilters(true)}
            className="filter-button"
          >
            <FilterIcon /> Filtros
          </Button>
        </div>

        {/* Modal para filtros */}
        <Modal show={showFilters} onHide={() => setShowFilters(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Filtros</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Filter filters={tempFilters} setFilters={setTempFilters} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClearFilters}>
              Limpiar
            </Button>
            <Button variant="danger" onClick={handleApplyFilters}>
              Aplicar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ListWithPagination */}
        <div className="list-wrapper mt-4 p-3 bg-white rounded shadow-sm">
          <ListWithPagination filters={filters} />
        </div>
      </div>

      <footer className="login-footer">
        <p>© {new Date().getFullYear()} DEH Online. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default Dashboard;
