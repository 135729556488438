import { API } from "../config/config";

const SubscriptionService = {
  getAllSubscriptions: (start = 0, limit = 10, filters = {}) => {
    const params = {
      ...filters,
      start,
      limit,
    };
    const token = localStorage.getItem("authToken");
    return API.get("/api/subscriptions", {
      params,
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
        throw error;
      });
  },
};

export default SubscriptionService;
