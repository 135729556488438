import React, { useState, useEffect } from 'react';
import SubscriptionService from '../services/SubscriptionService';
import List from './List';
import { Dropdown, DropdownButton, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import '../styles/ListWithPagination.css';
import useDebounce from '../hooks/useDebounce';

export type Filters = {
  codigo_suscripcion: string;
  payment_option: string;
  recurrency: string;
  payment_method: string;
  subscr_status: string;
  cif_account: string;
  legal_name_account: string;
  descripcion: string;
};

interface Item {
  codigo_suscripcion: string;
  payment_option: string;
  recurrency: string;
  last_payment: string | null;
  next_payment: string | null;
  payment_method: string;
  subscr_status: string;
  cif_account: string;
  legal_name_account: string;
  cif_consultant: string;
  legal_name_consultant: string;
  descripcion: string;
  price: number;
  remuneration: number | null;
}

interface ListWithPaginationProps {
  filters: Filters;
}

const ListWithPagination: React.FC<ListWithPaginationProps> = ({ filters }) => {
  const [items, setItems] = useState<Item[]>([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [pendingPage, setPendingPage] = useState(currentPage);

  const debouncedPage = useDebounce(pendingPage, 300);

  const fetchData = async (start: number, limit: number, filters: Filters) => {
    setLoading(true);
    setIsRefreshing(true);
    try {
      const data = await SubscriptionService.getAllSubscriptions(start, limit, filters);
      const { pagination, suscripciones } = data;
      const { totalPages: total, page } = pagination;

      setItems(suscripciones);
      setTotalPages(total);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setTimeout(() => setIsRefreshing(false), 500);
    }
  };

  useEffect(() => {
    fetchData(start, limit, filters);
  }, [start, limit, filters]);

  useEffect(() => {
    if (debouncedPage !== currentPage && debouncedPage >= 1 && debouncedPage <= totalPages) {
      setStart((debouncedPage - 1) * limit);
      setCurrentPage(debouncedPage);
    }
  }, [debouncedPage]);

  const handleLimitChange = (value: number) => {
    setLimit(value);
    setStart(0);
    setCurrentPage(1);
  };

  const handlePageInputChange = (value: string) => {
    const pageNumber = Number(value);
    if (!isNaN(pageNumber)) {
      setPendingPage(pageNumber);
    }
  };

  useEffect(() => {
    setStart(0);
    setCurrentPage(1);
  }, [filters]);

  return (
    <div className="list-with-pagination">
      {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      )}
      <div className={`list-container ${isRefreshing ? 'refreshing' : ''}`}>
        <List items={items} />
      </div>
      <div className="pagination-controls">
        <div className="page-selector">
          <InputGroup>
            <InputGroup.Text>Página</InputGroup.Text>
            <FormControl
              type="number"
              value={pendingPage}
              onChange={(e) => handlePageInputChange(e.target.value)}
              min="1"
              max={totalPages}
            />
            <InputGroup.Text>de {totalPages}</InputGroup.Text>
          </InputGroup>
        </div>
        <div className="items-per-page">
          <span className="items-label">Elementos por página:</span>
          <DropdownButton
            id="items-per-page-dropdown"
            title={`${limit}`}
            onSelect={(eventKey) => handleLimitChange(Number(eventKey))}
            variant="outline-secondary"
          >
            <Dropdown.Item eventKey="1">1</Dropdown.Item>
            <Dropdown.Item eventKey="5">5</Dropdown.Item>
            <Dropdown.Item eventKey="10">10</Dropdown.Item>
            <Dropdown.Item eventKey="20">20</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default ListWithPagination;
